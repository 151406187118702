import React, { useEffect, useState } from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { StaticImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import ScrollBtn from "../components/button/scroll-btn"

import { LazyLoadComponent } from "react-lazy-load-image-component"

import { IoMdQuote } from "react-icons/io"

import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const QuandaryValues = loadable(() =>
  pMinDelay(import("../components/new-quandary/quandary-value-career"), 500)
)
const Referral = loadable(() =>
  pMinDelay(import("../components/WorkLifeSwiper"), 500)
)
const Testimonials = loadable(() =>
  pMinDelay(import("../components/testimonials-career"), 500)
)

const Banner = props => {
  // const { heroImage } = props

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        className="career-banner"
        // preserveStackingContext
        style={{
          backgroundPosition: "bottom center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="hero-procurement careers-hero">
                <h1 className="page-title font-weight-medium mt-0 mb-4">
                  Join Our Team
                </h1>
                <p className="mb-4">
                  Work with leading developers and consultants to free
                  businesses from their systems so they can dominate their
                  markets.
                </p>
                <a href="#jobposting">
                  <p className="h5 text-center">View Openings</p>
                </a>
                <div className="d-flex justify-content-center">
                  <ScrollBtn to="#jobposting" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Careers = props => {
  const { heroImage, allSanityCareer } = useStaticQuery(
    graphql`
      {
        heroImage: file(relativePath: { eq: "new-images/career_bgimg.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        allSanityCareer {
          totalCount
          edges {
            node {
              _id
              _createdAt
              datePosted
              description
              employmentType
              excerpt
              id
              salary
              title
              location
              cta
              slug {
                current
              }
            }
          }
          location: group(field: location) {
            fieldValue
          }
          title: group(field: title) {
            fieldValue
          }
        }
      }
    `
  )

  const [searchText, setSearchText] = useState("")
  const [jobItems, setJobItems] = useState(
    allSanityCareer ? allSanityCareer.edges : []
  )

  const strLowerCase = str => (str ? str.toLowerCase() : false)
  const containStr = (str, text) =>
    strLowerCase(str) && strLowerCase(str).includes(text)
  const handleJobItems = () => {
    return allSanityCareer && allSanityCareer.edges
      ? allSanityCareer.edges.filter(item => {
          const { excerpt, location, title } = item.node
          return (
            containStr(excerpt, searchText) ||
            containStr(location, searchText) ||
            containStr(title, searchText)
          )
        })
      : []
  }

  // useEffect(() => {
  //   // Get a reference to the iframe element
  //   const iframe = document.getElementById("jobposting")

  //   // Access the content window of the iframe
  //   const iframeDocument = iframe.contentDocument || iframe.contentWindow

  //   // Manipulate the elements inside the iframe
  //   const iframeElement = iframeDocument.getElementsByClassName(
  //     "jobs-navbar"
  //   )[0]
  //   iframeElement.style.color = "red"

  //   // Clean up the effect
  //   return () => {
  //     // Reset any changes made inside the iframe before the component unmounts
  //     iframeElement.style.color = "" // or whatever reset value you need
  //   }
  // }, []) // Empty dependency array to run the effect only once

  useEffect(() => {
    if (allSanityCareer) {
      setJobItems(allSanityCareer.edges)
    }
  }, [])
  useEffect(() => {
    setJobItems(handleJobItems)
  }, [searchText])

  useEffect(() => {
    const els = document.getElementsByClassName("btn btn-link btn-icon-right")
    console.log("test", els)
  })

  console.log("jobItems => ", jobItems)
  console.log("allSanityCareer => ", allSanityCareer)
  return (
    <Layout banner={<Banner />} location={props.location}>
      <Seo
        title="Careers | Quandary Consulting Group"
        description="Join our team of technology consultants and integration specialists to help businesses reduce menial tasks. Great work life balance. Remote team. Learn more."
      />
      <section>
        <div className="container-fluid px-0">
          <div
            className="bg-indigo careers-banner"
            style={{
              backgroundSize: "cover",
            }}
          >
            <div className="container">
              <div className="row ">
                <div className="col-lg-9 mx-auto text-center">
                  <div className="hero-custom-content-quote text-center">
                    <h1 className="font-weight-bold m-0 text-white">
                      <IoMdQuote />
                    </h1>
                    <h2 className="page-title font-weight-medium mt-2 text-white">
                      Our value stems from growing our clients. Our success
                      stems from growing our teammates.
                    </h2>
                    <p className="h4 page-title font-weight-medium mt-3 text-white">
                      Kevin Shuler, Quandary CEO
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="jobposting" className="jazzHR-plugin py-5">
        <div className="mobile-overlay" />
        <LazyLoadComponent>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="relative">
                  <iframe
                    scrolling="yes"
                    name="resumator-job-frame"
                    id="resumator-job-frame"
                    title="resumator job"
                    class="resumator-advanced-widget"
                    src="https://quandaryconsultinggroup.applytojob.com/apply/"
                    width="100%"
                    height="500px"
                    frameborder="0"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    style={{ overflow: "scroll" }}
                    allowTransparency="true"
                  ></iframe>

                  <div className="nav-jobs-posting mx-auto w-100 text-center justify-content-between d-flex">
                    {/* <a
                      href="https://quandaryconsultinggroup.applytojob.com/apply"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="view-all-jobs"
                    >
                      <i class="fa fa-chevron-left mr-2"></i>
                      View All Jobs
                    </a> */}
                    {/* <a href="/" className="view-our-website">
                      View Our Website
                      <i class="fa fa-chevron-right ml-2"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LazyLoadComponent>
      </section>

      <section
        id="logos-wrapper"
        className="perks bg-white quandary-connect-glance"
        style={{ padding: "3rem 0 0" }}
      >
        <h2 className="my-2 text-center">Our Perks and Benefits</h2>
        <div className="container">
          <div className="row">
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/savings.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">401K</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/dental-care.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Dental Insurance</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/health-insurance.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Life Insurance</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/healthcare.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Medical Insurance</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/spiritual-wellness.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Wellness Service</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/pto.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">Unlimited PTO</p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/reimburse.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">
                  Professional Development <br /> Reimbursement
                </p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/profit.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">
                  Year-End Profit Sharing
                  <br /> and Bonus
                </p>
              </div>
            </div>
            <div className="col-xs-6 col-lg-4 col-md-6 mt-3 text-center mx-auto">
              <div className="">
                <div style={{ height: "3.5rem" }}>
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid"
                    src="../images/computer.png"
                    alt="Perspective"
                    layout="constrained"
                    style={{ width: "60px", maxWidth: "60px" }}
                    loading="lazy"
                  />
                </div>
                <p className="my-4">
                  Company Equipment <br />
                  (Mac or PC)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{
          padding: "0rem 0",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center"></div>
          </div>
          <div
            className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3 pb-4"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
          >
            <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
              <h3>About Quandary</h3>
              <p>
                We are a consulting firm based in Denver, Colorado with a simple
                mission: to design better systems that save businesses time and
                money. Business transformation isn’t easy and it’s never
                “finished”. That’s why we position ourselves as an extension of
                our client’s business. We prioritize our client success (both
                now and in the future) and we provide a level of responsiveness
                unheard of in technology consulting.
              </p>
              <Link
                className="btn btn-primary btn-arrow  mb-lg-0 mb-3 career-btn"
                aria-label="Quandary Connect"
                to="/about-us/"
              >
                Learn More About Quandary
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 py-lg-3 py-3">
              <StaticImage
                placeholder="blurred"
                className="rounded d-flex img-fluid"
                src="../images/new-images/mountain.jpg"
                alt="Phase 1"
                layout="constrained"
                loading="lazy"
              />
            </div>
          </div>
          <div className="row align-items-center flex-lg-row flex-md-row mt-3">
            <div className="col-lg-6 col-md-6 py-lg-4 py-3">
              <StaticImage
                placeholder="blurred"
                className="rounded d-flex img-fluid"
                src="../images/new-images/Image2.jpg"
                alt="Phase 2"
                layout="constrained"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
              <h3>Our Team</h3>
              <p>
                We hire the person. Not the position. Each person on our team
                adds to our company culture and values. They care about the work
                they do. And they strive to help our clients succeed. Our
                leadership team values transparency and feedback. They’re
                willing to admit mistakes, recognize individual talents, support
                opinions, and listen to disagreements, all while considering the
                team’s overall health and well-being.
              </p>
              <Link
                className="btn btn-primary btn-arrow mb-lg-0 career-btn"
                aria-label="Quandary Connect"
                to="/about-us/"
              >
                Meet the Team
              </Link>
            </div>
          </div>
          <div
            className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
          >
            <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
              <h3>Our Clients</h3>
              <p>
                We work with growing businesses that understand the powering of
                investing in better systems and processes. And we work closely
                with our clients to seamlessly guide them to the right solutions
                to automate workflows while providing deeper data insights. With
                a 100% approval rate, our results speak for themselves.
              </p>
              <Link
                className="btn btn-primary btn-arrow mb-lg-0 mb-3 career-btn"
                aria-label="Quandary Connect"
                to="/case-studies/"
              >
                See Our Success Stories
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 py-lg-3 py-3">
              <StaticImage
                placeholder="blurred"
                className="rounded d-flex img-fluid"
                src="../images/new-images/Image3.jpg"
                alt="Phase 3"
                layout="constrained"
                loading="lazy"
              />
            </div>
          </div>
          <div className="row align-items-center flex-lg-row flex-md-row mt-3">
            <div className="col-lg-6 col-md-6 py-lg-4 py-3">
              <StaticImage
                placeholder="blurred"
                className="rounded d-flex img-fluid img-size-careers"
                src="../images/new-images/Image4.jpg"
                alt="Phase 2"
                layout="constrained"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile results">
              <h3>Our Remote Work Lifestyle</h3>
              <p>
                We support a positive, healthy work-life balance for our team.
                And we ensure this by offering:
              </p>
              <ul>
                <li>A Transparent Work Culture</li>
                <li>Remote Work Environment</li>
                <li>Unlimited PTO</li>
                <li>Flexible Scheduling</li>
                <li>Creative Incentives</li>
                <li>Wellness Services</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: "1rem 0 3rem" }} className="peaks">
        <div
          className="container"
          style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
        >
          <div className="row">
            <div className="col-lg-8 mx-auto text-center pt-4">
              <h2>Our Work-Life Balance</h2>
            </div>
            <div className="col-lg-12 mt-0">
              <Referral />
            </div>
          </div>
        </div>
      </section>
      <section className="peaks" style={{ paddingBottom: "3rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center text-left-mobile">
              <h2>
                Our <span>"</span>PEAKS<span>"</span> Values
              </h2>
              <p>
                PEAKS serves as the foundation for our team values, guiding us
                toward continuous improvement in the work we do and the results
                we provide.
              </p>
            </div>
            <div className="col-lg-12 mt-3">
              <QuandaryValues />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey">
        <Testimonials />
      </section>
      <LazyLoadComponent>
        <section id="" className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div
                  className="text-center intro"
                  style={{
                    borderRadius: "5px",

                    background: "#fff",
                    padding: "3rem 42px",
                  }}
                >
                  <h2 className="mt-0 font-weight-medium" style={{}}>
                    Looking for more insights before you apply?
                  </h2>

                  <Link
                    className="text-light btn btn-arrow btn-primary mt-3"
                    to="/contact/"
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LazyLoadComponent>
    </Layout>
  )
}

export default Careers
