import React from "react"
import { Link } from "gatsby"

const ScrollBtn = ({ to }) => {
  return (
    <>
      <Link aria-label="link" to={to}>
        <div className="box">
          <span />
        </div>
      </Link>
    </>
  )
}

export default ScrollBtn
