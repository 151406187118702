import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import HorizontalMenu from "../sidebar/horizontalmenu"

import { StaticImage } from "gatsby-plugin-image"

const Header = ({ location, dataSrc }) => {
  const rootPath = `${__PATH_PREFIX__}/`

  return (
    <header
      className={location.pathname === rootPath ? "homepage" : "mainpages"}
      style={{ backgroundColor: "#00989b" }}
    >
      <div id="nav-menu" className="menu-container sticky-on">
        <div className="container py-2">
          <div className="row d-flex align-items-center">
            <div className="col-5 col-md-3">
              <div className="nav-brand">
                <Link aria-label="link" to="/" className="d-block">
                  <StaticImage
                    imgClassName="img-fluid"
                    className="w-75 w-md-100 h-auto"
                    src="../../../static/assets/qcg-logo-color-2021.jpg"
                    alt="logo"
                    layout="constrained"
                    priority
                    // width={195}
                    // height={195}
                  />
                </Link>
              </div>
            </div>
            <div className="col-7 col-md-9 ml-auto px-1 px-md-3 ">
              <div className="d-none d-lg-inline">
                <HorizontalMenu dataSrc={dataSrc} />
              </div>
              <div className="d-inline d-lg-none">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
