import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import "../sass/main.scss"
import "./layout.css"
import Header from "./header/header"

const ExitIntent = loadable(() =>
  pMinDelay(import("./modal/exit-intent-email"), 5000)
)

const Footer = loadable(() => import("./footer/footer"))

//hooks

// if (typeof window !== "undefined") {
//   if (window.fbq != null) {
//     window.fbq("track", "PageView")
//   }
// }

const Layout = ({ children, banner, location }) => {
  const [isloadClass, setIsLoadClass] = useState(false)
  useEffect(() => {
    if (location.pathname !== "/contact") localStorage.removeItem("title")
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      sanityCategory {
        title
      }

      allSanityCaseStudy(
        sort: { fields: [publishedAt], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            title
            publishedAt(formatString: "MMMM DD, YYYY")
            mainImage {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  width: 580
                )
              }
            }
            bannerImage {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  width: 580
                )
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const dataSrc = data?.allSanityCaseStudy

  useEffect(() => {
    if (location.pathname === "/careers/") {
      setIsLoadClass(true)
    } else {
      setIsLoadClass(false)
    }
  }, [setIsLoadClass])

  return (
    <>
      {isloadClass ? (
        <Helmet>
          <script>
            {`
            setTimeout(function(){
              var targetEl = document.getElementsByClassName("zsiq_floatmain"); 
            for(var i=0; i<targetEl.length; i++) { 
              targetEl[i].style.setProperty("display", "none", "important");
              };
            },5000)
            `}
          </script>
          <script>
            {`
            setTimeout(function(){
              var targetElo = document.getElementsByClassName("zls-sptwndw"); 
            for(var i=0; i<targetElo.length; i++) { 
              targetElo[i].style.setProperty("display", "none", "important");
              };
            },5000)
            `}
          </script>
        </Helmet>
      ) : (
        <Helmet>
          <script>
            {`
              setTimeout(function(){
                var links = document.querySelectorAll('.zsiq_floatmain');  
    for (var i = 0, length = links.length; i < length; i++) {  
        if (links[i].hostname != window.location.hostname) {
            links[i].style.setProperty("display", "block", "important"); 
        }
    }
              },5000)
            `}
          </script>
          <script>
            {`setTimeout(function() {
              var links = document.querySelectorAll('.zls-sptwndw');  
            for (var i = 0, length = links.length; i < length; i++) {  
                if (links[i].hostname != window.location.hostname) {
                    links[i].style.setProperty("display", "block", "important");
                }
            }
            },5000)
            `}
          </script>
        </Helmet>
      )}

      <Header
        siteTitle={data.site.siteMetadata.title}
        location={location}
        dataSrc={dataSrc}
      />

      {data.sanityCategory?.title !== "Citizen Development" ? (
        <ExitIntent />
      ) : null}
      {banner}
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
